var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "10",
      "lg": "7"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('b-form-group', {
    staticClass: "mr-1",
    attrs: {
      "label": "Dari"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.tgl_awal,
      callback: function callback($$v) {
        _vm.tgl_awal = $$v;
      },
      expression: "tgl_awal"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mr-1",
    attrs: {
      "label": "Sampai"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.tgl_akhir,
      callback: function callback($$v) {
        _vm.tgl_akhir = $$v;
      },
      expression: "tgl_akhir"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "-"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": !_vm.isValidForm
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.getData($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "FilterIcon"
    }
  }), _vm._v("Filter")], 1)], 1)], 1)])], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "10",
      "lg": "6"
    }
  }, _vm._l(_vm.rankSales, function (rank, i) {
    return _c('div', {
      key: i,
      staticClass: "d-flex align-items-center w-100"
    }, [_c('h1', {
      staticClass: "mr-2",
      staticStyle: {
        "padding": "1.5rem 0",
        "margin-bottom": "2rem"
      }
    }, [_c('strong', {
      staticClass: "text-"
    }, [_vm._v(_vm._s(++i))])]), _c('b-card', {
      staticClass: "w-100"
    }, [_c('section', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [rank.url_foto ? _c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "width": "50",
        "height": "50",
        "rounded": "circle",
        "src": rank.url_foto
      }
    }) : _c('b-avatar', {
      staticClass: "badge-minimal mr-1",
      attrs: {
        "size": "45",
        "variant": "light-primary"
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "UserIcon",
        "size": "22"
      }
    })], 1), _c('h5', [_vm._v(_vm._s(rank.nama_lengkap))])], 1), _c('h4', {
      staticClass: "text-success"
    }, [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(rank.value)))])])])])], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }